.progressValue {
  background-color: #354aad;
  border-radius: 2px;
  color: #ffc100;
  font-size: 18px;
  height: 18px;
  right: -16px;
  line-height: 26px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -17px;
  width: 32px;
  z-index: 2;
  max-width: 32px !important;
}

/*Triangluar tip on numerical indicator*/
.progressValue:before {
  content: "";
  position: absolute;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid #354aad;
  top: 16px;
  left: 10px;
  width: 6px;
  z-index: 2;
}

.meterBackground {
  background-color: #e9f1f4;
  border-radius: 2px;
  width: 10%;
  height: 2.1875rem;
  position: absolute;
  border-bottom: 2px solid #0237a1;
  border-radius: 3px;
}

.meterBar {
  background-color: #f5ad03;
  border-radius: 2px;
  display: block;
  width: 50%;
  height: 2.1875rem;
  border-bottom: 2px solid #0237a1;
  position: relative;
}

.scale {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100% !important;
  height: 10px;
  background-color: whitesmoke;
}

.tick {
  height: 0.78356rem;
  width: 1.2px;
  background-color: #0237a1;
}

progress {
  display: none !important;
}
@font-face {
  font-family: "screenmatrix";
  src: url("../game3/ScreenMatrix.ttf") format("truetype");
}

.font-ericsson {
  font-family: "screenmatrix";
}
.z-index {
  z-index: 2000;
}
.inventoryzero{
  /* background:  linear-gradient(95deg, #f6743e -6.96%, #d42525 108.25%) !important; */
  background:  linear-gradient(95deg, #f6743e -6.96%, #f9a17b 108.25%) !important;
}

.inventoryzero-btn {
  background:  linear-gradient(95deg, #f6743e -6.96%, #d42525 108.25%) !important;
}